package com.picme.components

import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.Page
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.picme.*
import com.picme.sdk2.generated.collection2.GetUploadResponse2
import com.picme.sdk2.generated.collection2.UploadId
import com.picme.views.editCaption
import com.picme.views.imageActions
import com.picme.views.imageInfo
import kotlin.random.Random


class ImageViewPager(
    val images: Readable<List<ImageDisplayInfo>>,
    val incomingImage: RecyclableInfo,
) : Page {
    val imageId = Property(incomingImage)

    private val imagesWithAds = shared { addAdsToImages(images()) }

    override fun ViewWriter.render2(): ViewModifiable = unpadded - col {
        spacing = 0.dp
        themeChoice = ViewPagerButtonSementic
        val currImage = shared { imagesWithAds().first { it.id == imageId().id } }

        val caption = Property("")
        val dirtyCaption = Property("")

        val isAd = shared { imageId().isAd }

        val details: Readable<GetUploadResponse2?> = shared {
            if (currImage().isAd) null
            else session.awaitNotNull().collection2.getUploadLive(
                collectionId = currentCollection()?.collectionId!!,
                uploadId = currImage().id.let(::UploadId)
            )()
        }

        suspend fun deleteImage() {
            if (currentCollection() == null) return
            val isLast =
                imagesWithAds().indexOfFirst { it.id == imageId().id } == imagesWithAds().size - 1
            deleteImages(
                (currentCollection()!!.images()).all().filter { it.uploadId.raw == currImage().id }
            )
            if (isLast) dialogScreenNavigator.dismiss()
        }

        val showBottomActions = shared { isSmallScreen() && !isAd() }


        ThemeDerivation {
            theme.copy(background = Color.picmeAuthGrey).withoutBack
        }.onNext - compact - unpadded - col {
            spacing = 0.dp
            expanding - sizeConstraints(minHeight = 68.dp) - stack {
                themeChoice += ThemeDerivation { it.copy(cornerRadii = appMaterialTheme.cornerRadii).withBack }
                reactiveSuspending {
                    caption set (details()?.upload?.caption?.raw ?: "")
                    dirtyCaption set (details()?.upload?.caption?.raw ?: "")
                }
                unpadded - row {
                    iconButtonTheme - button {
                        spacing = 0.dp
                        smallIcon(PIcon.chevronleft)
                        onClick { dialogScreenNavigator.dismiss() }
                    }
                    // space to center caption/title
                    space(multiplier = 30.0) {
                        ::exists { !showBottomActions() && !isAd() }
                    }
                    expanding - centered - col {
                        spacing = 0.dp
                        ::exists { !isAd() }
                        centered - button {
                            centered - bold - h4 {
                                ::content {
                                    val permittedLen = if (showBottomActions()) 50 else 100
                                    val title = details()?.upload?.caption?.raw?.let {
                                        if (it.length <= permittedLen) it
                                        else {
                                            "${it.substring(0, permittedLen)}..."
                                        }
                                    } ?: ""
                                    title.ifBlank {
                                        if (details()?.upload?.mimeType?.raw?.contains("video") == true) {
                                            "Video"
                                        } else "Photo"
                                    }
                                }
                            }
                            onClick {
                                editCaption(caption, dirtyCaption, currImage)
                            }
                        }
                    }

                    atEnd - row {
                        ::exists { !isAd() }
                        iconButtonTheme - button {
                            smallIcon(PIcon.info)
                            onClick {
                                imageInfo(details, caption, dirtyCaption, currImage)
                            }
                        }
                        space()
                        onlyWhen { !showBottomActions() && !isAd() } - imageActions(
                            false,
                            currImage,
                            details,
                            ::deleteImage
                        )
                    }

                    centered - expanding - col {
                        ::exists { isAd() }
                        centered - h4 { content = "Advertisement" }
                    }

                    atEnd - row {
                        ::exists { isAd() }
                        visible = false
                        iconButtonTheme - sizeConstraints(minWidth = 1.5.rem, minHeight = 1.5.rem) - button {
                            smallIcon(PIcon.info)
                            onClick {}
                        }
                        space()
                        onlyWhen { !showBottomActions() && !isAd() } - imageActions(
                            false,
                            currImage,
                            details,
                            ::deleteImage
                        )
                    }
                }
            }

            unpadded - col {
                themeChoice = PicmeAuthGreyBackgroundSementic
                centered - ThemeDerivation {
                    theme.copy(font = theme.font.copy(weight = 400, size = 14.dp)).withBack
                }.onNext - text {
                    spacing = 0.dp
                    themeChoice += FourHundredWeightFontSementic + PicmeAuthGreyBackgroundSementic
                    ::content {
                        if (isAd()) " "
                        else
                            "${
                                details()?.let {
                                    "Uploaded " + formatIsoDate(it.upload.uploadTime.toString())
                                } ?: ""
                            } ${
                                details()?.uploader?.uploaderName?.let {
                                    if (it.isBlank()) ""
                                    else "by $it"
                                } ?: ""
                            }"

                    }
                }
                separatorTheme - separator { }
            }
        }
        unpadded - expanding - col {
            spacing = 0.dp
            viewPagerWithAds(imagesWithAds, imageId)
        }

        bar - unpadded - col {
            spacing = 0.dp
            unpadded - separatorTheme - separator {
                ::exists { !isAd() }
            }
            ::exists { showBottomActions() }
            padded - imageActions(
                true,
                currImage,
                details,
                ::deleteImage
            )
        }
        // empty space to make a smooth transition when viewing an ad
        important - bar - unpadded - col {
            ::exists { isAd() && isSmallScreen() }
            sizeConstraints(height = 4.5.rem) - row { }
        }
    }
}

fun addAdsToImages(images: List<ImageDisplayInfo>): MutableList<ImageDisplayInfo> {
    return images.fold(mutableListOf()) { acc, curr ->
        acc.add(curr)
        if (acc.size % 5 == 0) {
            acc.add(
                ImageDisplayInfo(
                    // Android viewpager needs to have all unique ids or else it will scroll to the last one of the same id
                    id = "ad" + Random.nextFloat().toString(),
                    thumbnail = Resources.picMePaigeAdVertical,
                    isAd = true,
                    mimeType = curr.mimeType
                )
            )
        }
        acc
    }
}
package com.picme.views.share

import com.lightningkite.kiteui.Blob
import com.lightningkite.kiteui.DownloadLocation
import com.lightningkite.kiteui.ExternalServices
import com.lightningkite.kiteui.Platform
import com.lightningkite.kiteui.current
import com.lightningkite.kiteui.models.Align
import com.lightningkite.kiteui.models.Color
import com.lightningkite.kiteui.models.CornerRadii
import com.lightningkite.kiteui.models.ImageRaw
import com.lightningkite.kiteui.models.PopoverPreferredDirection
import com.lightningkite.kiteui.models.ThemeDerivation
import com.lightningkite.kiteui.models.WordBreak
import com.lightningkite.kiteui.models.dp
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.navigation.mainScreenNavigator
import com.lightningkite.kiteui.navigation.screenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.bold
import com.lightningkite.kiteui.views.card
import com.lightningkite.kiteui.views.centered
import com.lightningkite.kiteui.views.closePopovers
import com.lightningkite.kiteui.views.compact
import com.lightningkite.kiteui.views.danger
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.dynamicTheme
import com.lightningkite.kiteui.views.expanding
import com.picme.FadedForeground
import com.picme.InviteType
import com.picme.PIcon
import com.picme.actuals.toBlob
import com.picme.appMaterialTheme
import com.picme.components.*
import com.picme.currentCollection
import com.picme.formCol
import com.picme.iconButtonTheme
import com.picme.isSmallScreen
import com.picme.picmeAuthGrey
import com.picme.picmeButtonSecondary
import com.picme.sdk2.GenerateQr
import com.picme.sdk2.QrType
import com.picme.sdk2.generated.CollectionId
import com.picme.sdk2.generated.CreateQrCodeImageResponse
import com.picme.sdk2.generated.PatchInviteCodeBody
import com.picme.session
import com.picme.sharePath
import com.picme.showToast
import com.picme.validDownloadableName
import com.picme.views.QrAccept
import com.picme.views.frontendUrl
import kotlin.io.encoding.Base64
import kotlin.io.encoding.ExperimentalEncodingApi

class ShareView(val collectionId: CollectionId, val onBack: suspend () -> Unit) : Screen {
    private val withinCollContext = false
    private val alwaysLarge = false

    final override fun ViewWriter.render() {
        val constrainWidth = 32.rem

        // TODO: Switch to reactive size constraints in a future release after this feature is added to KiteUI
        scrolls - unpadded - stack {
            unpadded - sizeConstraints(width = constrainWidth) - swapView {
                swapping(
                    current = label@{
                        if (isSmallScreen() || alwaysLarge) {
                            return@label ScreenType1.SmallScreen
                        }
                        if (withinCollContext) {
                            return@label ScreenType1.CollectionLargeScreen
                        }
                        ScreenType1.LargeScreen
                    },
                    views = {
                        appMaterialTheme.onNext - unpadded - col {
                            spacing = 0.dp
                            row {
                                spacing = 0.dp
                                space()
                                row {
                                    spacing = 0.dp
                                    iconButtonTheme - button {
                                        smallIcon(PIcon.chevronleft)
                                        onClick {
                                            onBack()
                                        }
                                    }
                                    centered - h3 {
                                        content = "Share Collection"
                                    }
                                }
                            }
                            padded - stack {
                                unpadded - row {
                                    space(multiplier = 0.25)
                                    expanding - content()
                                    space(multiplier = 0.25)
                                }
                            }
                        }
                    }
                )
            }
        }
    }

    suspend fun ViewWriter.close() {
        val visibleAsDialog = dialogScreenNavigator.currentScreen.awaitOnce() == this@ShareView
        val visibleAsScreen = screenNavigator.currentScreen.awaitOnce() == this@ShareView
        if (visibleAsDialog) dialogScreenNavigator.dismiss()
        if (visibleAsScreen) screenNavigator.goBack()
    }

    val invite = Property<ViewInviteInfo?>(null)

    private fun ViewWriter.content() {
        col {
            spacing = 0.dp
            row {
                spacing = 0.dp
                space(0.25)
                text { content = "Guest access" }
            }
            space(0.75)
            invites(collectionId, invite)
            space(0.5)
            themeChoice += ThemeDerivation { it.copy(cornerRadii = CornerRadii.ForceConstant(10.dp)).withBack }
            card - row { expanding - inviteQrDisplay(invite) {
                val qrCodeInfo = shared { invite.awaitNotNull() }
                formCol {
                    spacing = 0.dp
                    stack {
                        exists = true; ::exists{ !qrCodeInfo().inviteCode.temporarilyDisabled }
                        centered - compact - danger - button {
                            text { content = "Pause This Invitation" }
                            onClick {
                                showConfirmDialog(
                                    title = "Confirm Pause",
                                    content = "This Invitation will be paused.",
                                    confirmLabel = "Pause",
                                    onConfirm = {
                                        session.awaitNotNull().collection2.patchInviteCode(
                                            qrCodeInfo().inviteCode.inviteCodeId,
                                            PatchInviteCodeBody(temporarilyDisabled = true)
                                        )
                                        onBack()
                                    },
                                )
                            }
                        }
                    }
                    centered - stack {
                        spacing = 0.dp
                        exists = false; ::exists{ qrCodeInfo().inviteCode.temporarilyDisabled }
                        centered - compact - danger - button {
                            text { content = "Enable This Invitation" }
                            onClick {
                                showConfirmDialog(
                                    title = "Confirm Enable",
                                    content = "This Invitation will be enabled.",
                                    confirmLabel = "Enable",
                                    onConfirm = {
                                        session.awaitNotNull().collection2.patchInviteCode(
                                            qrCodeInfo().inviteCode.inviteCodeId,
                                            PatchInviteCodeBody(temporarilyDisabled = false)
                                        )
                                        onBack()
                                    },
                                )
                            }
                        }
                    }
                }

            } }
        }
    }

}

@OptIn(ExperimentalEncodingApi::class)
fun ViewWriter.inviteQrDisplay(qrCodeInfo: Readable<ViewInviteInfo?>, additionalContent: ContainingView.() -> Unit) =
    col {
        val enabled = shared {
            if (qrCodeInfo()?.inviteCode?.temporarilyDisabled == null) false
            else !qrCodeInfo()?.inviteCode?.temporarilyDisabled!!
        }
        centered -  col {
            spacing = 0.rem
            val pngQrCode = sharedSuspending {
                GenerateQr.getQrCode(
                    hostName = frontendUrl(),
                    path = qrCodeInfo()?.inviteCode?.sharePath()?:"",
                    QrType.Png
                ).await()
            }

            val fileBlob = shared {
                Base64.decode(pngQrCode().base64Encoded)
                    .toBlob(pngQrCode().contentType.raw)
            }

            space()

            centered - sizeConstraints(width = 18.rem) - stack {
                centered - sizeConstraints(width = 12.rem, height = 12.rem) - centered - image {
                    exists = false
                    ::exists { pngQrCode().base64Encoded; true }
                    ::source {
                        ImageRaw(fileBlob())
                    }
                }
                centered - sizeConstraints(width = 12.rem, height = 12.rem) - centered - ThemeDerivation {
                    it.copy(background = Color.picmeAuthGrey).withBack
                }.onNext - stack {
                    centered - activityIndicator()
                    exists = true
                    ::exists { pngQrCode().base64Encoded; false }
                }
                centered - sizeConstraints(width = 12.rem, height = 12.rem) - stack {
                    dynamicTheme {
                        if (!enabled()) {
                            ThemeDerivation {
                                it.copy(background = Color.white.applyAlpha(0.8f), outlineWidth = 0.dp).withBack
                            }
                        } else null
                    }
                }
            }
            space(multiplier = 0.25)
            subtext {
                align = Align.Center
                wraps = true
                wordBreak = WordBreak.BreakAll
                ::content { qrCodeInfo()?.link?.removePrefix("https://") ?: "" }
            }

            space()
            centered - row {
                iconButtonTheme - ThemeDerivation {
                    it.copy(
                        iconOverride = Color.black,
                        foreground = Color.black,
                        background = Color.picmeButtonSecondary
                    ).withBack
                }.onNext - downloadButton(enabled, qrCodeInfo, pngQrCode, fileBlob)
                iconButtonTheme - ThemeDerivation {
                    it.copy(
                        iconOverride = Color.black,
                        foreground = Color.black,
                        background = Color.picmeButtonSecondary
                    ).withBack
                }.onNext - button {
                    smallIcon(PIcon.eye)
                    ::enabled { enabled() }
                    onClick {
                        if (Platform.current === Platform.Web) {
                            ExternalServices.openTab(qrCodeInfo()?.link ?: "")
                        } else {
                            mainScreenNavigator.navigate(QrAccept(qrCodeInfo()?.inviteCode?.inviteCodeId?.raw?:""))
                        }
                    }
                }
                iconButtonTheme - ThemeDerivation {
                    it.copy(
                        iconOverride = Color.black,
                        foreground = Color.black,
                        background = Color.picmeButtonSecondary
                    ).withBack
                }.onNext - button {
                    smallIcon(PIcon.copy)
                    ::enabled { enabled() }
                    onClick {
                        ExternalServices.setClipboardText(qrCodeInfo()?.link ?: "")
                        showToast("Url copied to clipboard")
                    }
                }
                iconButtonTheme - ThemeDerivation {
                    it.copy(
                        iconOverride = Color.black,
                        foreground = Color.black,
                        background = Color.picmeButtonSecondary
                    ).withBack
                }.onNext - button {
                    smallIcon(PIcon.share)
                    ::enabled { enabled() }
                    onClick {
                        if (qrCodeInfo()?.permission?.name != null && qrCodeInfo()?.link != null) {
                            ExternalServices.share(title = qrCodeInfo()!!.permission.name, url = qrCodeInfo()!!.link)
                        }
                    }
                }
            }
            space()
            centered - FadedForeground.onNext - text {
                ::exists { qrCodeInfo()?.permission != InviteType.Referral }
                ::content { "Permissions: ${qrCodeInfo()?.permission?.readable()}" }
            }
        }
        additionalContent()
    }

@OptIn(ExperimentalEncodingApi::class)
private fun RowOrCol.downloadButton(
    enabled: Readable<Boolean>,
    qrCodeInfo: Readable<ViewInviteInfo?>,
    pngQrCode: Readable<CreateQrCodeImageResponse>,
    fileBlob: Readable<Blob>,
): MenuButton = menuButton {
    ::enabled { enabled() }
    requireClick = true
    preferredDirection = PopoverPreferredDirection.belowRight
    centered - sizeConstraints(minWidth = 1.5.rem, minHeight = 1.5.rem) - icon {
        source = PIcon.download
    }
    opensMenu {
        popoverWrapper {
            col {
                spacing = 5.dp
                bold - text("File Format")
                popoverItemTheme - button {
                    text(".png")
                    onClick {
                        ExternalServices.download(
                            name = "${currentCollection()?.name ?: "qr"}_${qrCodeInfo()?.inviteCode?.name}${pngQrCode().fileExtension}".replace(
                                " ",
                                "_"
                            ).validDownloadableName(),
                            blob = fileBlob(),
                            preferredDestination = DownloadLocation.Pictures
                        )
                        val message = when (Platform.current) {
                            Platform.Web -> "QR code downloaded"
                            Platform.iOS -> "QR code saved to Camera Roll"
                            else -> "QR code downloaded"
                        }
                        showToast(message)
                        closePopovers()
                    }
                }
                popoverItemTheme - button {
                    text(".svg")
                    onClick {
                        val newQrCode = GenerateQr.getQrCode(
                            hostName = frontendUrl(),
                            path = qrCodeInfo()?.inviteCode?.sharePath() ?: "",
                            QrType.Svg
                        ).await()

                        ExternalServices.download(
                            name =
                                "${currentCollection()?.name ?: "qr"}_${qrCodeInfo()?.inviteCode?.name}.svg".replace(
                                    " ",
                                    "_"
                                ).validDownloadableName(),
                            blob = Base64.decode(newQrCode.base64Encoded)
                                .toBlob(newQrCode.contentType.raw),
                        )
                        showToast("QR code downloaded")
                        closePopovers()
                    }
                }
            }
        }
    }
}
package com.picme.sso

import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.mainScreenNavigator
import com.lightningkite.kiteui.reactive.Action
import com.lightningkite.kiteui.reactive.Property
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.reactive.reactive
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.centered
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.expanding
import com.picme.Resources
import com.picme.navigateToCollOrLanding
import com.picme.sdk2.Session
import com.picme.sdk2.generated.UserAuthenticated
import com.picme.sdk2.generated.sso.ContinueAppleAuthenticationResponse
import com.picme.sdk2.unauthApi
import com.picme.separatorTheme
import com.picme.session
import com.picme.views.LoginOrSignUp
import com.picme.views.selectedBackend

fun ViewWriter.startSSO() {
    val googleEnabled = Property(false)
    val appleEnabled = Property(false)

    reactive {
        googleEnabled.value = selectedBackend().contains("dev")
        appleEnabled.value = selectedBackend().contains("dev")
    }

    col {
        ::exists { googleEnabled() || appleEnabled() }
        sizeConstraints(height = 2.rem) - row {
            spacing = 8.dp
            expanding - col { expanding - stack(); separatorTheme - separator(); expanding - stack() }
            centered - subtext("OR")
            expanding - col { expanding - stack(); separatorTheme - separator(); expanding - stack() }
        }
        googleSsoOverride?.let {
            button {
                ::exists { googleEnabled() }
                spacing = 0.dp
                themeChoice += ThemeDerivation { it.copy(cornerRadii = CornerRadii.ForceConstant(100.rem)).withoutBack }
                image { source = Resources.ssoGoogleLogin }
                action = Action("Google Login", Icon.login, action = it)
            }
        } ?: run {
            externalLink {
                ::exists { googleEnabled() }
                spacing = 0.dp
                themeChoice += ThemeDerivation { it.copy(cornerRadii = CornerRadii.ForceConstant(100.rem)).withoutBack }
                image { source = Resources.ssoGoogleLogin }
                ::to { "${selectedBackend()}/a/SsoHandler/sso/google?configName=&responseUriId=&setTosRead=true" }
            }
        }
        appleSsoOverride?.let { authenticateWithApple ->
            button {
                ::exists { appleEnabled() }
                spacing = 0.dp
                themeChoice += ThemeDerivation { it.copy(cornerRadii = CornerRadii.ForceConstant(100.rem)).withoutBack }
                image { source = Resources.ssoAppleLogin }
                action = Action("Apple Login", Icon.login, action = {
                    authenticateWithApple().let {
                        val userAuth = it.successfulAuthentication

                        if (userAuth == null) {
                            mainScreenNavigator.reset(LoginOrSignUp(false))
                        } else {
                            val newSession = Session(
                                unauthApi(), UserAuthenticated(
                                    accessToken = userAuth.authenticated.accessToken,
                                    tokenType = "access_token",
                                    refreshToken = userAuth.authenticated.refreshToken,
                                    expiresInSeconds = 100
                                ), userAuth.authenticatedUser
                            )
                            println(newSession.collection2.listCollectionsLive().all())
                            session set newSession
                            navigateToCollOrLanding()
                        }
                    }
                })
            }
        } ?: run {
            externalLink {
                ::exists { appleEnabled() }
                spacing = 0.dp
                themeChoice += ThemeDerivation { it.copy(cornerRadii = CornerRadii.ForceConstant(100.rem)).withoutBack }
                image { source = Resources.ssoAppleLogin }
                ::to { "${selectedBackend()}/a/SsoHandler/sso/apple?configName=&responseUriId=&setTosRead=true" }
            }
        }
    }
}

var appleSsoOverride: (suspend () -> ContinueAppleAuthenticationResponse)? = null
var googleSsoOverride: (suspend () -> Unit)? = null